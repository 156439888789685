import React from "react";
import { GoArrowRight } from "react-icons/go";
import { Link } from "react-router-dom";

export default function WhatWeDo() {
  return (
    <div>
      <div className="grid lg:grid-cols-12 grid-cols-1 xl:gap-4   ">
        <div className="lg:col-span-8  h-full">
          <div className="grid lg:grid-cols-3 grid-cols-1 h-full text-white">
            <div className="h-[120px]  lg:border-r-0 border-[0.5px] border-[#3e98ff73] bg-[#3e98ff0e] hover:bg-black transition ease-in-out cursor-pointer   backdrop-blur-[100px] w-full  flex justify-center px-5 flex-col ">
              <h4 className="font-medium md:text-[20px]">Development</h4>
              <p className="md:text-sm lg:text-xs text-sm mt-1">
                Building secure, scalable smart contracts and dApps for your
                Web3 projects.
              </p>
            </div>
            <div className=" h-[120px] border-[0.5px] border-[#3e98ff73] bg-[#3e98ff0e] hover:bg-black transition ease-in-out cursor-pointer   backdrop-blur-[100px] w-full  flex justify-center px-5 flex-col lg:border-r-0">
              <h4 className="font-medium md:text-[20px]">Design </h4>
              <p className="md:text-sm lg:text-xs text-sm mt-1">
                Creating sleek, user-friendly interfaces for seamless Web3
                experiences.
              </p>
            </div>
            <div className="h-[120px] border-[0.5px] border-[#3e98ff73] bg-[#3e98ff0e] hover:bg-black transition ease-in-out cursor-pointer   backdrop-blur-[100px] w-full flex justify-center px-5 flex-col ">
              <h4 className="font-medium md:text-[20px]">Marketing </h4>
              <p className="md:text-sm lg:text-xs text-sm mt-1">
                Driving growth with targeted Web3 strategies and community
                engagement.
              </p>
            </div>
          </div>
        </div>
        <div className="md:col-span-4 border-[0.5px] border-[#3e98ff73] h-full">
          <Link
            target="_blank"
            to="https://docs.google.com/forms/d/e/1FAIpQLSegSEcgWzHfocKSm2sw8JDEH6RKwYu6JfGEv9UAwkWXAsWIyA/viewform?usp=header"
          >
            <div className="lg:h-[60px] h-[120px] anime cursor-pointer w-full flex md:flex-row flex-col md:justify-between md:items-center justify-center gap-3  px-5  text-white">
              <div className="flex  xl:gap-3 items-center">
                {" "}
                <h5 className="lg:text-xs">Buildorz is Hiring</h5>
                <GoArrowRight />
              </div>
              <h5 className="lg:text-xs">Top Blockchain Developers</h5>
            </div>
          </Link>
          <Link target="_blank" to="https://wa.me/919968554061">
            <div className="lg:h-[60px] h-[120px] cursor-pointer w-full flex md:flex-row flex-col md:justify-between md:items-center justify-center gap-3  px-5  text-white">
              <div className="flex xl:gap-3 items-center">
                {" "}
                <h5 className="lg:text-xs">Get in Touch </h5>
                <GoArrowRight />
              </div>
              <h5 className="lg:text-xs">Shoot a message right now!!</h5>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter } from "react-router-dom";
import WhatWeDo from "./modules/home/component/whatwedo";
import { Hero } from "./modules/home/feautures/Hero";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Hero />
        <div className="-mt-[20vh] px-6 lg:hidden block py-20">
          <WhatWeDo />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;

"use client";
import { motion } from "framer-motion";
import { HeroHighlight, Highlight } from "./Herolight";
import Spline from "@splinetool/react-spline";
import Navbar from "../component/navbar";
import { GoArrowRight } from "react-icons/go";
import Typewriter from "typewriter-effect";
import WhatWeDo from "../component/whatwedo";

export function Hero() {
  const textArray = [
    "The driving force behind your Web 3.0 venture's growth, innovation, and success, empowering you to thrive in the decentralized future.",
    "The spark that drives your Web 3.0 venture to grow and thrive, enabling transparency, scalability, and innovation through cutting-edge decentralized solutions.",
  ];
  return (
    <div className="w-full lg:h-screen ">
      <HeroHighlight className=" w-full  h-full relative">
        <div className="absolute top-0 left-0 z-20 w-full">
          <Navbar />
        </div>
        <div className="absolute top-0 left-0 w-full h-full  ">
          <Spline
            scene="https://prod.spline.design/JZ29mFMxXhtCJQ6E/scene.splinecode"
            className=""
          />
        </div>
        <div className="lg:block hidden  absolute bottom-7 left-0 z-20 w-full xl:px-[48px] md:px-[48px] lg:px-[32px]  px-6 bg-black">
          <WhatWeDo />
        </div>

        <div className="absolute h-full flex md:items-center items-start  xl:px-[48px] md:px-[48px] lg:px-[32px]  px-6 text-white">
          <div className="md:-mt-[150px] mt-[150px]">
            <h1 className="text-white text-[30px] leading-[30px] font-semibold">
              Web3 Growth <br /> Catalysts
            </h1>

            <div className="w-[300px] text-sm w mt-4">
              <Typewriter
                options={{
                  strings: textArray,
                  autoStart: true,
                  delay: 25,
                  loop: true,
                  typeSpeed: 50,
                }}
              />
            </div>
          </div>
        </div>
      </HeroHighlight>
    </div>
  );
}

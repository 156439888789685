import { AnimatePresence, motion } from "framer-motion";
import logo from "../../../../assets/big_logo.png";
import React, { useState } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";

import {
  containerVars,
  menuVars,
  mobileLinkVars,
} from "../../../../utils/motion";
export default function Navbar() {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const closeMenu = () => {
    setOpen(false);
  };
  const nav = [
    { title: "LinkdedIn", link: "https://www.linkedin.com/company/buildorz" },
    {
      title: "Hiring",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSegSEcgWzHfocKSm2sw8JDEH6RKwYu6JfGEv9UAwkWXAsWIyA/viewform?usp=header",
    },
    { title: "Contact Us", link: "https://wa.me/919968554061" },
  ];
  return (
    <div className="fixed  w-full ">
      <div className="] w-full">
        <div className="">
          <div className="h-[10vh] flex justify-between items-center w-full xl:px-[48px] md:px-[48px] lg:px-[32px]  px-6 ">
            <Link to="/">
              <img className="md:w-[150px] w-[90px]" src={logo} alt="logo" />
            </Link>
            <nav className="flex items-center text-white lg:gap-7">
              <ul className="lg:flex hidden lg:gap-7 items-center text-sm uppercase">
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/company/buildorz"
                >
                  {" "}
                  <h4 className="text-sm cursor-pointer hover:text-[#3E98FF] transition ease-in-out">
                    LinkedIn
                  </h4>
                </Link>
                <Link
                  target="_blank"
                  to="https://docs.google.com/forms/d/e/1FAIpQLSegSEcgWzHfocKSm2sw8JDEH6RKwYu6JfGEv9UAwkWXAsWIyA/viewform?usp=header"
                >
                  <div className="relative">
                    <h4 className="text-sm cursor-pointer hover:text-[#3E98FF] transition ease-in-out">
                      Hiring
                    </h4>

                    <div className="absolute top-0 -right-[14px] w-[8px] h-[8px] bg-[#3E98FF] rounded-full " />
                  </div>
                </Link>
                <Link to="https://wa.me/919968554061" target="_blank">
                  <h4 className="text-sm cursor-pointer hover:text-[#3E98FF] transition ease-in-out">
                    Contact Us
                  </h4>
                </Link>
              </ul>

              <div className="lg:hidden flex">
                <HiOutlineMenuAlt3
                  onClick={toggleMenu}
                  className="text-[30px] text-white"
                />
              </div>
            </nav>
          </div>
        </div>

        <div>
          {" "}
          <AnimatePresence>
            {open && (
              <motion.div
                variants={menuVars}
                initial="initial"
                animate="animate"
                exit="exit"
                className="fixed z-[10000000] left-0 top-0 h-screen w-full origin-top bg-black text-white py-[7%] px-6 "
              >
                <div className="flex h-full flex-col">
                  <div className="flex justify-between">
                    <Link href="/">
                      {" "}
                      <div>
                        <img
                          className="md:w-[150px] w-[90px]"
                          src={logo}
                          alt="logo"
                        />
                      </div>
                    </Link>
                    <p className="text-md cursor-pointer " onClick={toggleMenu}>
                      Close
                    </p>
                  </div>
                  <motion.div
                    variants={containerVars}
                    initial="initial"
                    animate="open"
                    exit="initial"
                    className="flex h-full flex-col items-center justify-center gap-4"
                  >
                    {nav.map((item) => (
                      <div>
                        <MobileNavLink title={item.title} link={item.link} />
                      </div>
                    ))}
                  </motion.div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
const MobileNavLink = ({ title, link }) => {
  return (
    <motion.div
      variants={mobileLinkVars}
      className="text-2xl uppercase text-white"
    >
      <NavLink to={link}>{title}</NavLink>
    </motion.div>
  );
};
